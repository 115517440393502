

@keyframes moveLines {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 1000px 1000px; 
    }
  }
  
  .homepage {
    position: relative;
  }
  
  .background-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #f2f2f2; 
    animation: moveLines 20s linear infinite; 
    background-image:
      linear-gradient(45deg, transparent 50%, rgba(51, 153, 102, 0.1) 50%),
      linear-gradient(-45deg, transparent 50%, rgba(51, 153, 102, 0.1) 50%); 
    background-size: 50px 50px; 
  }
  