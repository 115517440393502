.container {
    width: 450px; /* Decreased width */
    height: 600px; /* Decreased height */
    margin: auto;
    margin-top: 70px; /* Decreased margin-top */
    border-radius: 10px; /* Decreased border radius */
    background-image: linear-gradient(180deg, #052b68 0%, #0e668f 100%);
    overflow: hidden;
    transition: transform 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Decreased box shadow */
}

.container.zoomed {
    transform: scale(1.1); /* Adjusted scale factor */
}

.top-bar {
    display: flex;
    justify-content: center;
    gap: 14px; /* Adjusted gap */
    padding-top: 40px; /* Decreased padding-top */
}

.top-bar input {
    display: flex;
    width: 320px; /* Adjusted width */
    height: 60px; /* Adjusted height */
    background: white;
    border: none;
    outline: none;
    border-radius: 30px; /* Adjusted border-radius */
    padding-left: 25px; /* Adjusted left padding */
    color: grey;
    font-size: 20px; /* Adjusted font-size */
    font-weight: 400;
}

.search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px; /* Adjusted width */
    height: 60px; /* Adjusted height */
    background: white;
    border-radius: 30px; /* Adjusted border-radius */
    cursor: pointer;
}

.weather-image {
    margin-top: 20px; /* Adjusted top margin */
}

.weather-temp {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 90px; /* Adjusted font-size */
    font-weight: 300;
}

.weather-location {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 45px; /* Adjusted font-size */
    font-weight: 400;
}

.data-container {
    margin-top: 30px; /* Adjusted top margin */
    display: flex;
    color: white;
    justify-content: center;
}

.element {
    margin: auto;
    display: flex;
    align-items: flex-start;
    gap: 10px; /* Adjusted gap */
}

.data {
    font-size: 28px; /* Adjusted font-size */
    font-weight: 300;
}

.text {
    font-size: 16px; /* Adjusted font-size */
    font-weight: 400;
}

.icon {
    margin-top: 7px; /* Adjusted top margin */
}

.humidity,
.wind {
    font-size: 28px; /* Adjusted font-size */
    font-weight: 300;
}

.zoom-button {
    position: absolute;
    top: 10px; /* Adjusted top position */
    right: 10px; /* Adjusted right position */
    padding: 10px; /* Adjusted padding */
    background-color: #4CAF50;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.zoom-button:hover {
    background-color: #45a049;
}
