.header-logo {
    color: #508d00;
    align-items: center;
    text-align: center;
    justify-content: center;
    transform: scale(0.8); /* Adjust the scale value to make the logo smaller */
}


.header-nav{
background-color: #508d00;
float:right;
padding:10px 20px 10px 20px;
}


.header-nav-link{
display:inline-block;
font-size:20px;
color:white;
text-decoration:none;
padding:10px 20px 10px 20px;
}

.image-size{
    width: 100px;
    height: 100px;
    border-radius:50%;
}

.text-center{
    text-align:center;
    justify-content: center;
}