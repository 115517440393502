.text-block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px;
    gap: 25px;
    margin: 20px;
}

.card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px;
    box-shadow: 0 10px 30px -4px rgba(0, 0, 0, .15);
    transition: transform .3s;
}

.card-container:hover {
    transform: scale(1.1); /* Increase size by 10% on hover */
}

.card-img-wrapper {
    margin: auto;
    font-size: 25px;
    text-align: center;
}

.card-description {
    font-size: 16px;
    text-align: center;
}

div {
    display: block;
}

.icon {
    color: greenyellow;
    font-size: 80px;
}

.image-size {
    width: 100px;
    height: 100px;
}
